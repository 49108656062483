<template>
  <div>
    <HeroInputDate v-model="date1" id="date1" label="Date1" />
    Date1 = {{ date1 }}

    <hr />
    <HeroButtonModal v-b-modal="'sandbox-modal-1'" type="button" variant="primary">
      Sandbox Modal 1
    </HeroButtonModal>

    <SandboxModal1 />
  </div>
</template>

<script>
import HeroButtonModal from '@/views/components/form/buttons/HeroButtonModal.vue'
import HeroInputDate from '@/views/components/form/inputs/HeroInputDate.vue'
import SandboxModal1 from '@/views/sandbox/SandboxModal1.vue'

export default {
  components: {
    HeroButtonModal,
    HeroInputDate,
    SandboxModal1,
  },
  data() {
    return {
      date1: null,
    }
  },
}
</script>
